<template>
  <div>
    <Head>
      <Title>Krožnik.si | Rešujemo hrano</Title>
      <Meta
        name="description"
        content="Krožnik rešuje hrano pred smetmi. Povezujemo trgovce in kupce za boljši svet."
      />
    </Head>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import { AuthApi, Configuration, type UserInfo } from './client_api'
import { useUserStore } from '~/stores/user.store'
import { axiosInstance } from './client_api/exios'

const headers = useRequestHeaders()
const { data, error } = await useFetch('/api/user', { headers })

const userStore = useUserStore()

onMounted(() => {
  if (error.value === null) {
    userStore.setIsLoggedIn(true)
    userStore.setUser(data.value as UserInfo)
  } else {
    userStore.setIsLoggedIn(false)
  }
  new AuthApi(new Configuration(), '', axiosInstance).authCsrfRetrieve({ withCredentials: true })
})
</script>
