import { default as create_45accountwIX2mfi0Y4Meta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/create-account.vue?macro=true";
import { default as email_45confirmation_45success8uYjpYszFNMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/email-confirmation-success.vue?macro=true";
import { default as _91token_93S92bEHkEGjMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/password-confirm/[uid]/[token].vue?macro=true";
import { default as password_45resetRLKPnm4ZfQMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/password-reset.vue?macro=true";
import { default as index8EN6UPwtvjMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/dashboard/index.vue?macro=true";
import { default as indexgRt9UFUJflMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/index.vue?macro=true";
import { default as detailsYp1eZS1WCDMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/inventory/[id]/details.vue?macro=true";
import { default as indexWWdlnSCgBoMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/inventory/create/index.vue?macro=true";
import { default as indexuwKfvkFLyyMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/inventory/index.vue?macro=true";
import { default as index06LuGk31bHMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/orders/index.vue?macro=true";
import { default as policyGo2FSDhgb1Meta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/policy.vue?macro=true";
import { default as mapgiXuDHXIIkMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/[id]/map.vue?macro=true";
import { default as pickup_45timedweJSW5dsiMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/[id]/pickup-time.vue?macro=true";
import { default as indexbzTfb5FaRFMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/index.vue?macro=true";
import { default as newWUCB9zXxq9Meta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/new.vue?macro=true";
import { default as companyHmajn6He1TMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/company.vue?macro=true";
import { default as indexLQktNoYfXUMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/index.vue?macro=true";
import { default as securityGdQUlqfsfaMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/security.vue?macro=true";
import { default as team6pWl7KeZUKMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/team.vue?macro=true";
import { default as supportjTbNBiG5CKMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/support.vue?macro=true";
import { default as terms_45of_45useqzWdqRO6LPMeta } from "/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/terms-of-use.vue?macro=true";
export default [
  {
    name: "auth-create-account",
    path: "/auth/create-account",
    meta: create_45accountwIX2mfi0Y4Meta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/create-account.vue").then(m => m.default || m)
  },
  {
    name: "auth-email-confirmation-success",
    path: "/auth/email-confirmation-success",
    meta: email_45confirmation_45success8uYjpYszFNMeta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/email-confirmation-success.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-confirm-uid-token",
    path: "/auth/password-confirm/:uid()/:token()",
    meta: _91token_93S92bEHkEGjMeta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/password-confirm/[uid]/[token].vue").then(m => m.default || m)
  },
  {
    name: "auth-password-reset",
    path: "/auth/password-reset",
    meta: password_45resetRLKPnm4ZfQMeta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/auth/password-reset.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexgRt9UFUJflMeta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inventory-id-details",
    path: "/inventory/:id()/details",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/inventory/[id]/details.vue").then(m => m.default || m)
  },
  {
    name: "inventory-create",
    path: "/inventory/create",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/inventory/create/index.vue").then(m => m.default || m)
  },
  {
    name: "inventory",
    path: "/inventory",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/inventory/index.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    meta: policyGo2FSDhgb1Meta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices-id-map",
    path: "/settings/branch-offices/:id()/map",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/[id]/map.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices-id-pickup-time",
    path: "/settings/branch-offices/:id()/pickup-time",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/[id]/pickup-time.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices",
    path: "/settings/branch-offices",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-branch-offices-new",
    path: "/settings/branch-offices/new",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/branch-offices/new.vue").then(m => m.default || m)
  },
  {
    name: "settings-company",
    path: "/settings/company",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/company.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-security",
    path: "/settings/security",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/security.vue").then(m => m.default || m)
  },
  {
    name: "settings-team",
    path: "/settings/team",
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/settings/team.vue").then(m => m.default || m)
  },
  {
    name: "support",
    path: "/support",
    meta: supportjTbNBiG5CKMeta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45useqzWdqRO6LPMeta || {},
    component: () => import("/Users/klemen/Desktop/Development/kroznik/monorepo/kroznik_backoffice/pages/terms-of-use.vue").then(m => m.default || m)
  }
]