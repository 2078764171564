import { defineStore } from 'pinia'
import { AuthApi, Configuration, type UserInfo } from '~/client_api'
import { axiosInstanceAuth } from '~/client_api/exios'

export interface userState {
  user: null | UserInfo
  isLoggedIn: boolean | null
}

export const useUserStore = defineStore('user', {
  state: (): userState => ({
    isLoggedIn: null,
    user: null,
  }),

  actions: {
    setUser(user: UserInfo) {
      this.user = user
    },
    setIsLoggedIn(value: boolean) {
      this.isLoggedIn = value
    },

    async fetchUserData() {
      if (this.isLoggedIn !== null) {
        return
      }
      try {
        const resp = await new AuthApi(
          new Configuration(),
          '',
          axiosInstanceAuth
        ).authUserMeRetrieve()
        this.user = resp.data
        this.isLoggedIn = true
      } catch (err) {
        this.isLoggedIn = false
      }
    },
  },
})
