/* tslint:disable */
/* eslint-disable */
/**
 * Kroznik OpenApi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * * `retail` - retail * `company` - company
 * @export
 * @enum {string}
 */

export const AccountTypeEnum = {
    Retail: 'retail',
    Company: 'company'
} as const;

export type AccountTypeEnum = typeof AccountTypeEnum[keyof typeof AccountTypeEnum];


/**
 * 
 * @export
 * @interface ActiveInAppNotification
 */
export interface ActiveInAppNotification {
    /**
     * 
     * @type {string}
     * @memberof ActiveInAppNotification
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof ActiveInAppNotification
     */
    'body': string;
}
/**
 * 
 * @export
 * @interface AddMember
 */
export interface AddMember {
    /**
     * 
     * @type {string}
     * @memberof AddMember
     */
    'email': string;
    /**
     * 
     * @type {MembershipTypeEnum}
     * @memberof AddMember
     */
    'membership_type': MembershipTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof AddMember
     */
    'branch_office_id': number;
}


/**
 * 
 * @export
 * @interface AddToCartDto
 */
export interface AddToCartDto {
    /**
     * 
     * @type {number}
     * @memberof AddToCartDto
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof AddToCartDto
     */
    'quantity': number;
}
/**
 * 
 * @export
 * @interface AppleLogin
 */
export interface AppleLogin {
    /**
     * 
     * @type {string}
     * @memberof AppleLogin
     */
    'id_token': string;
}
/**
 * 
 * @export
 * @interface BasicStatistics
 */
export interface BasicStatistics {
    /**
     * 
     * @type {number}
     * @memberof BasicStatistics
     */
    'number_of_reserved_orders': number;
    /**
     * 
     * @type {number}
     * @memberof BasicStatistics
     */
    'sold_items_last_month': number;
    /**
     * 
     * @type {Array<SoldPerDay>}
     * @memberof BasicStatistics
     */
    'sold_per_day': Array<SoldPerDay>;
}
/**
 * * `florist` - FLORIST * `bakery` - BAKERY * `candy` - CANDY * `cafe` - CAFE * `store` - STORE * `farm` - FARM * `restaurant` - RESTAURANT * `fast_food` - FAST_FOOD * `butcher` - BUTCHER * `pharmacy` - PHARMACY
 * @export
 * @enum {string}
 */

export const BranchOfficeCategoryEnum = {
    Florist: 'florist',
    Bakery: 'bakery',
    Candy: 'candy',
    Cafe: 'cafe',
    Store: 'store',
    Farm: 'farm',
    Restaurant: 'restaurant',
    FastFood: 'fast_food',
    Butcher: 'butcher',
    Pharmacy: 'pharmacy'
} as const;

export type BranchOfficeCategoryEnum = typeof BranchOfficeCategoryEnum[keyof typeof BranchOfficeCategoryEnum];


/**
 * 
 * @export
 * @interface BranchOfficeDetails
 */
export interface BranchOfficeDetails {
    /**
     * 
     * @type {Array<OpeningHoursDto>}
     * @memberof BranchOfficeDetails
     */
    'opening_hours': Array<OpeningHoursDto>;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'postal_code': string;
    /**
     * 
     * @type {boolean}
     * @memberof BranchOfficeDetails
     */
    'is_operational': boolean;
    /**
     * 
     * @type {number}
     * @memberof BranchOfficeDetails
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof BranchOfficeDetails
     */
    'loc_latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BranchOfficeDetails
     */
    'loc_longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'contact_email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BranchOfficeDetails
     */
    'cover_photo'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BranchOfficeDetails
     */
    'can_place_order_now'?: boolean;
    /**
     * 
     * @type {BranchOfficeCategoryEnum}
     * @memberof BranchOfficeDetails
     */
    'branch_office_category'?: BranchOfficeCategoryEnum | null;
}


/**
 * 
 * @export
 * @interface CartDto
 */
export interface CartDto {
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'price': number;
    /**
     * 
     * @type {Array<ProductInCartDto>}
     * @memberof CartDto
     */
    'products': Array<ProductInCartDto>;
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'expires_in': number;
    /**
     * 
     * @type {number}
     * @memberof CartDto
     */
    'for_branch_office_id': number;
}
/**
 * * `mixed` - MIXED * `meat` - MEAT * `fruit` - FRUIT * `vegetables` - VEGETABLES * `dairy` - DAIRY * `bakery` - BAKERY * `sweet` - SWEET
 * @export
 * @enum {string}
 */

export const CategoryEnum = {
    Mixed: 'mixed',
    Meat: 'meat',
    Fruit: 'fruit',
    Vegetables: 'vegetables',
    Dairy: 'dairy',
    Bakery: 'bakery',
    Sweet: 'sweet'
} as const;

export type CategoryEnum = typeof CategoryEnum[keyof typeof CategoryEnum];


/**
 * 
 * @export
 * @interface CompanyDetails
 */
export interface CompanyDetails {
    /**
     * 
     * @type {number}
     * @memberof CompanyDetails
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'address': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'city': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'country': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'postal_code': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'vat': string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyDetails
     */
    'phone': string | null;
    /**
     * 
     * @type {CompanyDetailsStatusEnum}
     * @memberof CompanyDetails
     */
    'status': CompanyDetailsStatusEnum;
}


/**
 * * `in_progress` - in_progress * `pending` - pending * `verified` - verified * `archived` - archived
 * @export
 * @enum {string}
 */

export const CompanyDetailsStatusEnum = {
    InProgress: 'in_progress',
    Pending: 'pending',
    Verified: 'verified',
    Archived: 'archived'
} as const;

export type CompanyDetailsStatusEnum = typeof CompanyDetailsStatusEnum[keyof typeof CompanyDetailsStatusEnum];


/**
 * 
 * @export
 * @interface CompanyLogin
 */
export interface CompanyLogin {
    /**
     * 
     * @type {string}
     * @memberof CompanyLogin
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyLogin
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyLogin
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CompanyMember
 */
export interface CompanyMember {
    /**
     * 
     * @type {number}
     * @memberof CompanyMember
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyMember
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyMember
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMember
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CompanyMember
     */
    'branch_office_id': number;
    /**
     * 
     * @type {string}
     * @memberof CompanyMember
     */
    'branch_office_name': string;
    /**
     * 
     * @type {MembershipTypeEnum}
     * @memberof CompanyMember
     */
    'membership_type': MembershipTypeEnum;
    /**
     * 
     * @type {MembershipStatusEnum}
     * @memberof CompanyMember
     */
    'membership_status': MembershipStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyMember
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyMember
     */
    'updated_on': string;
}


/**
 * 
 * @export
 * @interface CompanyMemberDetails
 */
export interface CompanyMemberDetails {
    /**
     * 
     * @type {number}
     * @memberof CompanyMemberDetails
     */
    'company_id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyMemberDetails
     */
    'user_id': number;
    /**
     * 
     * @type {number}
     * @memberof CompanyMemberDetails
     */
    'branch_office_id': number | null;
    /**
     * 
     * @type {MembershipTypeEnum}
     * @memberof CompanyMemberDetails
     */
    'membership_type': MembershipTypeEnum;
}


/**
 * 
 * @export
 * @interface CompanyUser
 */
export interface CompanyUser {
    /**
     * 
     * @type {CompanyDetails}
     * @memberof CompanyUser
     */
    'company': CompanyDetails;
    /**
     * 
     * @type {CompanyMemberDetails}
     * @memberof CompanyUser
     */
    'member': CompanyMemberDetails;
}
/**
 * 
 * @export
 * @interface CreateBranchOffice
 */
export interface CreateBranchOffice {
    /**
     * 
     * @type {string}
     * @memberof CreateBranchOffice
     */
    'contact_email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchOffice
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchOffice
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchOffice
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchOffice
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBranchOffice
     */
    'postal_code': string;
    /**
     * 
     * @type {BranchOfficeCategoryEnum}
     * @memberof CreateBranchOffice
     */
    'branch_office_category': BranchOfficeCategoryEnum;
}


/**
 * 
 * @export
 * @interface CreateDeviceToken
 */
export interface CreateDeviceToken {
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceToken
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface CreateProductDto
 */
export interface CreateProductDto {
    /**
     * 
     * @type {CategoryEnum}
     * @memberof CreateProductDto
     */
    'category': CategoryEnum;
    /**
     * 
     * @type {ProductClassificationEnum}
     * @memberof CreateProductDto
     */
    'product_classification': ProductClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProductDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'original_price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'discounted_price': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProductDto
     */
    'branch_office_id'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProductDto
     */
    'publish_now'?: boolean;
}


/**
 * Serializer for requesting a password reset e-mail.
 * @export
 * @interface CustomPasswordReset
 */
export interface CustomPasswordReset {
    /**
     * 
     * @type {string}
     * @memberof CustomPasswordReset
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomPasswordReset
     */
    'recaptcha_token': string;
}
/**
 * 
 * @export
 * @interface CustomRegister
 */
export interface CustomRegister {
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'username'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'password1': string;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'password2': string;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'recaptchaToken'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'name': string;
    /**
     * 
     * @type {AccountTypeEnum}
     * @memberof CustomRegister
     */
    'accountType': AccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomRegister
     */
    'invitation_token'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomRegister
     */
    'agree_to_terms': boolean;
}


/**
 * 
 * @export
 * @interface CustomerLogin
 */
export interface CustomerLogin {
    /**
     * 
     * @type {string}
     * @memberof CustomerLogin
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLogin
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomerLogin
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CustomerProductDetailsDto
 */
export interface CustomerProductDetailsDto {
    /**
     * 
     * @type {CategoryEnum}
     * @memberof CustomerProductDetailsDto
     */
    'category': CategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof CustomerProductDetailsDto
     */
    'branch_office_id': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerProductDetailsDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerProductDetailsDto
     */
    'product_name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CustomerProductDetailsDto
     */
    'original_price': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerProductDetailsDto
     */
    'discounted_price': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerProductDetailsDto
     */
    'remaining_quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerProductDetailsDto
     */
    'id': number;
}


/**
 * 
 * @export
 * @interface GoogleLogin
 */
export interface GoogleLogin {
    /**
     * 
     * @type {string}
     * @memberof GoogleLogin
     */
    'id_token': string;
}
/**
 * 
 * @export
 * @interface InvitationCompany
 */
export interface InvitationCompany {
    /**
     * 
     * @type {string}
     * @memberof InvitationCompany
     */
    'company_name': string;
}
/**
 * 
 * @export
 * @interface JwtResponseView
 */
export interface JwtResponseView {
    /**
     * 
     * @type {string}
     * @memberof JwtResponseView
     */
    'token': string;
}
/**
 * * `invitation_sent` - invitation_sent * `declined` - declined * `enabled` - enabled * `archived` - archived
 * @export
 * @enum {string}
 */

export const MembershipStatusEnum = {
    InvitationSent: 'invitation_sent',
    Declined: 'declined',
    Enabled: 'enabled',
    Archived: 'archived'
} as const;

export type MembershipStatusEnum = typeof MembershipStatusEnum[keyof typeof MembershipStatusEnum];


/**
 * * `admin` - admin * `operator` - operator * `branch_admin` - branch_admin
 * @export
 * @enum {string}
 */

export const MembershipTypeEnum = {
    Admin: 'admin',
    Operator: 'operator',
    BranchAdmin: 'branch_admin'
} as const;

export type MembershipTypeEnum = typeof MembershipTypeEnum[keyof typeof MembershipTypeEnum];


/**
 * 
 * @export
 * @interface NotificationPreferences
 */
export interface NotificationPreferences {
    /**
     * 
     * @type {boolean}
     * @memberof NotificationPreferences
     */
    'enabled': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const NullEnum = {
    Null: 'null'
} as const;

export type NullEnum = typeof NullEnum[keyof typeof NullEnum];


/**
 * 
 * @export
 * @interface OpeningHoursDto
 */
export interface OpeningHoursDto {
    /**
     * 
     * @type {number}
     * @memberof OpeningHoursDto
     */
    'id': number;
    /**
     * 
     * @type {boolean}
     * @memberof OpeningHoursDto
     */
    'is_open': boolean;
    /**
     * 
     * @type {number}
     * @memberof OpeningHoursDto
     */
    'day': number;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursDto
     */
    'opens_at': string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursDto
     */
    'closes_at': string;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursDto
     */
    'pickup_begins_at'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OpeningHoursDto
     */
    'pickup_ends_at'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OpeningHoursDto
     */
    'branch_office_id': number;
}
/**
 * 
 * @export
 * @interface OrderBranchOffice
 */
export interface OrderBranchOffice {
    /**
     * 
     * @type {number}
     * @memberof OrderBranchOffice
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderBranchOffice
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrderBranchOffice
     */
    'full_address': string;
}
/**
 * 
 * @export
 * @interface OrderDisplayDto
 */
export interface OrderDisplayDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDisplayDto
     */
    'user_id': number;
    /**
     * 
     * @type {string}
     * @memberof OrderDisplayDto
     */
    'uid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderDisplayDto
     */
    'collection_id'?: string | null;
    /**
     * 
     * @type {OrderDisplayDtoStatusEnum}
     * @memberof OrderDisplayDto
     */
    'status': OrderDisplayDtoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderDisplayDto
     */
    'full_price': number;
    /**
     * 
     * @type {Array<OrderProductDisplayDto>}
     * @memberof OrderDisplayDto
     */
    'products': Array<OrderProductDisplayDto>;
    /**
     * 
     * @type {string}
     * @memberof OrderDisplayDto
     */
    'created_on': string;
    /**
     * 
     * @type {string}
     * @memberof OrderDisplayDto
     */
    'updated_on': string;
    /**
     * 
     * @type {number}
     * @memberof OrderDisplayDto
     */
    'branch_office_id'?: number | null;
    /**
     * 
     * @type {OrderBranchOffice}
     * @memberof OrderDisplayDto
     */
    'branch_office'?: OrderBranchOffice | null;
}


/**
 * * `reserved` - RESERVED * `collected` - COLLECTED * `cancelled` - CANCELLED
 * @export
 * @enum {string}
 */

export const OrderDisplayDtoStatusEnum = {
    Reserved: 'reserved',
    Collected: 'collected',
    Cancelled: 'cancelled'
} as const;

export type OrderDisplayDtoStatusEnum = typeof OrderDisplayDtoStatusEnum[keyof typeof OrderDisplayDtoStatusEnum];


/**
 * * `INDIVIDUAL` - INDIVIDUAL * `BAG` - BAG
 * @export
 * @enum {string}
 */

export const OrderProductClassificationEnum = {
    Individual: 'INDIVIDUAL',
    Bag: 'BAG'
} as const;

export type OrderProductClassificationEnum = typeof OrderProductClassificationEnum[keyof typeof OrderProductClassificationEnum];


/**
 * 
 * @export
 * @interface OrderProductDisplayDto
 */
export interface OrderProductDisplayDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductDisplayDto
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDisplayDto
     */
    'order_id': number;
    /**
     * 
     * @type {CategoryEnum}
     * @memberof OrderProductDisplayDto
     */
    'category': CategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDisplayDto
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDisplayDto
     */
    'price_sum': number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDisplayDto
     */
    'price_per_item': number;
    /**
     * 
     * @type {OrderProductClassificationEnum}
     * @memberof OrderProductDisplayDto
     */
    'order_product_classification': OrderProductClassificationEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDisplayDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderProductDisplayDto
     */
    'product_name'?: string | null;
}


/**
 * 
 * @export
 * @interface PasswordChange
 */
export interface PasswordChange {
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'new_password1': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordChange
     */
    'new_password2': string;
}
/**
 * Serializer for confirming a password reset attempt.
 * @export
 * @interface PasswordResetConfirm
 */
export interface PasswordResetConfirm {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'new_password1': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'new_password2': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'uid': string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetConfirm
     */
    'token': string;
}
/**
 * 
 * @export
 * @interface PatchedChangeDefaultQuantityDto
 */
export interface PatchedChangeDefaultQuantityDto {
    /**
     * 
     * @type {number}
     * @memberof PatchedChangeDefaultQuantityDto
     */
    'new_quantity'?: number;
}
/**
 * 
 * @export
 * @interface PatchedChangeQuantityDto
 */
export interface PatchedChangeQuantityDto {
    /**
     * 
     * @type {number}
     * @memberof PatchedChangeQuantityDto
     */
    'new_quantity'?: number;
}
/**
 * 
 * @export
 * @interface PatchedCreateBranchOffice
 */
export interface PatchedCreateBranchOffice {
    /**
     * 
     * @type {string}
     * @memberof PatchedCreateBranchOffice
     */
    'contact_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCreateBranchOffice
     */
    'contact_phone'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedCreateBranchOffice
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCreateBranchOffice
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCreateBranchOffice
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedCreateBranchOffice
     */
    'postal_code'?: string;
    /**
     * 
     * @type {BranchOfficeCategoryEnum}
     * @memberof PatchedCreateBranchOffice
     */
    'branch_office_category'?: BranchOfficeCategoryEnum;
}


/**
 * 
 * @export
 * @interface PatchedInitCompanyInfo
 */
export interface PatchedInitCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof PatchedInitCompanyInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedInitCompanyInfo
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedInitCompanyInfo
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedInitCompanyInfo
     */
    'postal_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedInitCompanyInfo
     */
    'vat'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedInitCompanyInfo
     */
    'phone'?: string;
}
/**
 * 
 * @export
 * @interface PatchedUpdateBranchOfficeLocation
 */
export interface PatchedUpdateBranchOfficeLocation {
    /**
     * 
     * @type {number}
     * @memberof PatchedUpdateBranchOfficeLocation
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedUpdateBranchOfficeLocation
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface PatchedUpdateCompanyInfo
 */
export interface PatchedUpdateCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateCompanyInfo
     */
    'city'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateCompanyInfo
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateCompanyInfo
     */
    'address'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateCompanyInfo
     */
    'postal_code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateCompanyInfo
     */
    'vat'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateCompanyInfo
     */
    'phone'?: string | null;
}
/**
 * 
 * @export
 * @interface PatchedUpdateOpeningHoursDto
 */
export interface PatchedUpdateOpeningHoursDto {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUpdateOpeningHoursDto
     */
    'is_open'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateOpeningHoursDto
     */
    'opens_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateOpeningHoursDto
     */
    'closes_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateOpeningHoursDto
     */
    'pickup_begins_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateOpeningHoursDto
     */
    'pickup_ends_at'?: string;
}
/**
 * 
 * @export
 * @interface PatchedUpdateProductDto
 */
export interface PatchedUpdateProductDto {
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUpdateProductDto
     */
    'is_published'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedUpdateProductDto
     */
    'reset_product_at_midnight'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PatchedUpdateProductDto
     */
    'default_quantity'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedUpdateProductDto
     */
    'original_price'?: number;
    /**
     * 
     * @type {number}
     * @memberof PatchedUpdateProductDto
     */
    'discounted_price'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateProductDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateProductDto
     */
    'product_name'?: string | null;
}
/**
 * 
 * @export
 * @interface PatchedUpdateUserInfo
 */
export interface PatchedUpdateUserInfo {
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateUserInfo
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateUserInfo
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUpdateUserInfo
     */
    'phone'?: string;
}
/**
 * User model w/o password
 * @export
 * @interface PatchedUserDetails
 */
export interface PatchedUserDetails {
    /**
     * 
     * @type {number}
     * @memberof PatchedUserDetails
     */
    'pk'?: number;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserDetails
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserDetails
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserDetails
     */
    'last_name'?: string;
}
/**
 * 
 * @export
 * @interface PricingCalculationErrorResponse
 */
export interface PricingCalculationErrorResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationErrorResponse
     */
    'discounted_price': number;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationErrorResponse
     */
    'fees': number;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationErrorResponse
     */
    'diff': number;
    /**
     * 
     * @type {string}
     * @memberof PricingCalculationErrorResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface PricingCalculationResponse
 */
export interface PricingCalculationResponse {
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationResponse
     */
    'discounted_price': number;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationResponse
     */
    'fees': number;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationResponse
     */
    'profit_after_fees': number;
    /**
     * 
     * @type {number}
     * @memberof PricingCalculationResponse
     */
    'max_discount': number;
}
/**
 * * `INDIVIDUAL` - INDIVIDUAL * `BAG` - BAG
 * @export
 * @enum {string}
 */

export const ProductClassificationEnum = {
    Individual: 'INDIVIDUAL',
    Bag: 'BAG'
} as const;

export type ProductClassificationEnum = typeof ProductClassificationEnum[keyof typeof ProductClassificationEnum];


/**
 * 
 * @export
 * @interface ProductDetailsDto
 */
export interface ProductDetailsDto {
    /**
     * 
     * @type {CategoryEnum}
     * @memberof ProductDetailsDto
     */
    'category': CategoryEnum;
    /**
     * 
     * @type {ProductClassificationEnum}
     * @memberof ProductDetailsDto
     */
    'product_classification': ProductClassificationEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'branch_office_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'company_id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'original_price': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'discounted_price': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    'is_published': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'reserved_quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'bought_quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'remaining_quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'default_quantity': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDetailsDto
     */
    'reset_product_at_midnight': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductDetailsDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ProductDetailsDto
     */
    'product_name': string;
}


/**
 * 
 * @export
 * @interface ProductInCartDto
 */
export interface ProductInCartDto {
    /**
     * 
     * @type {number}
     * @memberof ProductInCartDto
     */
    'product_id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductInCartDto
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProductInCartDto
     */
    'price_sum': number;
    /**
     * 
     * @type {CategoryEnum}
     * @memberof ProductInCartDto
     */
    'category': CategoryEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductInCartDto
     */
    'quantity': number;
}


/**
 * 
 * @export
 * @interface PublicBranchOffice
 */
export interface PublicBranchOffice {
    /**
     * 
     * @type {number}
     * @memberof PublicBranchOffice
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PublicBranchOffice
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublicBranchOffice
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof PublicBranchOffice
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof PublicBranchOffice
     */
    'postal_code': string;
    /**
     * 
     * @type {number}
     * @memberof PublicBranchOffice
     */
    'loc_latitude'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PublicBranchOffice
     */
    'loc_longitude'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PublicBranchOffice
     */
    'cover_photo'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PublicBranchOffice
     */
    'pending_products': number;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBranchOffice
     */
    'is_open_today': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PublicBranchOffice
     */
    'can_place_order_now': boolean;
}
/**
 * 
 * @export
 * @interface ResendEmailVerification
 */
export interface ResendEmailVerification {
    /**
     * 
     * @type {string}
     * @memberof ResendEmailVerification
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface RestAuthDetail
 */
export interface RestAuthDetail {
    /**
     * 
     * @type {string}
     * @memberof RestAuthDetail
     */
    'detail': string;
}
/**
 * 
 * @export
 * @interface SoldPerDay
 */
export interface SoldPerDay {
    /**
     * 
     * @type {number}
     * @memberof SoldPerDay
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof SoldPerDay
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface StripeDashboardUrl
 */
export interface StripeDashboardUrl {
    /**
     * 
     * @type {string}
     * @memberof StripeDashboardUrl
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ToggleFavoriteDto
 */
export interface ToggleFavoriteDto {
    /**
     * 
     * @type {number}
     * @memberof ToggleFavoriteDto
     */
    'branch_office_id': number;
}
/**
 * 
 * @export
 * @interface ToggleOperationDto
 */
export interface ToggleOperationDto {
    /**
     * 
     * @type {boolean}
     * @memberof ToggleOperationDto
     */
    'status': boolean;
}
/**
 * Serializer for Token model.
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    'key': string;
}
/**
 * User model w/o password
 * @export
 * @interface UserDetails
 */
export interface UserDetails {
    /**
     * 
     * @type {number}
     * @memberof UserDetails
     */
    'pk': number;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetails
     */
    'last_name'?: string;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {number}
     * @memberof UserInfo
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'email': string;
    /**
     * 
     * @type {AccountTypeEnum}
     * @memberof UserInfo
     */
    'account_type': AccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'phone'?: string | null;
    /**
     * 
     * @type {UserProfile}
     * @memberof UserInfo
     */
    'profile': UserProfile;
}


/**
 * 
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
    /**
     * 
     * @type {boolean}
     * @memberof UserProfile
     */
    'send_push_notifications': boolean;
}
/**
 * 
 * @export
 * @interface VerifyEmail
 */
export interface VerifyEmail {
    /**
     * 
     * @type {string}
     * @memberof VerifyEmail
     */
    'key': string;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCsrfRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/csrf/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {CompanyLogin} companyLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjLoginCreate: async (companyLogin: CompanyLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyLogin' is not null or undefined
            assertParamExists('authDjLoginCreate', 'companyLogin', companyLogin)
            const localVarPath = `/api/auth/dj/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjLogoutCreate: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/dj/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjPasswordChangeCreate: async (passwordChange: PasswordChange, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordChange' is not null or undefined
            assertParamExists('authDjPasswordChangeCreate', 'passwordChange', passwordChange)
            const localVarPath = `/api/auth/dj/password/change/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordChange, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjPasswordResetConfirmCreate: async (passwordResetConfirm: PasswordResetConfirm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordResetConfirm' is not null or undefined
            assertParamExists('authDjPasswordResetConfirmCreate', 'passwordResetConfirm', passwordResetConfirm)
            const localVarPath = `/api/auth/dj/password/reset/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetConfirm, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
         * @param {CustomPasswordReset} customPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjPasswordResetCreate: async (customPasswordReset: CustomPasswordReset, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customPasswordReset' is not null or undefined
            assertParamExists('authDjPasswordResetCreate', 'customPasswordReset', customPasswordReset)
            const localVarPath = `/api/auth/dj/password/reset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customPasswordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomRegister} customRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjRegistrationCreate: async (customRegister: CustomRegister, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customRegister' is not null or undefined
            assertParamExists('authDjRegistrationCreate', 'customRegister', customRegister)
            const localVarPath = `/api/auth/dj/registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customRegister, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResendEmailVerification} resendEmailVerification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjRegistrationResendEmailCreate: async (resendEmailVerification: ResendEmailVerification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resendEmailVerification' is not null or undefined
            assertParamExists('authDjRegistrationResendEmailCreate', 'resendEmailVerification', resendEmailVerification)
            const localVarPath = `/api/auth/dj/registration/resend-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resendEmailVerification, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjRegistrationVerifyEmailCreate: async (verifyEmail: VerifyEmail, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verifyEmail' is not null or undefined
            assertParamExists('authDjRegistrationVerifyEmailCreate', 'verifyEmail', verifyEmail)
            const localVarPath = `/api/auth/dj/registration/verify-email/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verifyEmail, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {PatchedUserDetails} [patchedUserDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjUserPartialUpdate: async (patchedUserDetails?: PatchedUserDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/dj/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUserDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjUserRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/dj/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {UserDetails} [userDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjUserUpdate: async (userDetails?: UserDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/dj/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserMeRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/user/me/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRetailAccount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/delete-retail-account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login endpoint for retail customers in mobile app
         * @param {CompanyLogin} companyLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsCompany: async (companyLogin: CompanyLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyLogin' is not null or undefined
            assertParamExists('loginAsCompany', 'companyLogin', companyLogin)
            const localVarPath = `/api/auth/company-login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Login endpoint for retail customers in mobile app
         * @param {CustomerLogin} customerLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsCustomer: async (customerLogin: CustomerLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerLogin' is not null or undefined
            assertParamExists('loginAsCustomer', 'customerLogin', customerLogin)
            const localVarPath = `/api/auth/retail-login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customerLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppleLogin} appleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithApple: async (appleLogin: AppleLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appleLogin' is not null or undefined
            assertParamExists('loginWithApple', 'appleLogin', appleLogin)
            const localVarPath = `/api/auth/social-signup/apple/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appleLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GoogleLogin} googleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithGoogle: async (googleLogin: GoogleLogin, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleLogin' is not null or undefined
            assertParamExists('loginWithGoogle', 'googleLogin', googleLogin)
            const localVarPath = `/api/auth/social-signup/google/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleLogin, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NotificationPreferences} notificationPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        togglePushNotificationPreferences: async (notificationPreferences: NotificationPreferences, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationPreferences' is not null or undefined
            assertParamExists('togglePushNotificationPreferences', 'notificationPreferences', notificationPreferences)
            const localVarPath = `/api/auth/user/update-push-notification-preferences/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationPreferences, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchedUpdateUserInfo} [patchedUpdateUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyUserDetails: async (patchedUpdateUserInfo?: PatchedUpdateUserInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/user/me/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateUserInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyJwtToken: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/jwt/verify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authCsrfRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authCsrfRetrieve(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authCsrfRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {CompanyLogin} companyLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjLoginCreate(companyLogin: CompanyLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Token>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjLoginCreate(companyLogin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjLoginCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjLogoutCreate(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjLogoutCreate(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjLogoutCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjPasswordChangeCreate(passwordChange: PasswordChange, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjPasswordChangeCreate(passwordChange, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjPasswordChangeCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjPasswordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjPasswordResetConfirmCreate(passwordResetConfirm, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjPasswordResetConfirmCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
         * @param {CustomPasswordReset} customPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjPasswordResetCreate(customPasswordReset: CustomPasswordReset, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjPasswordResetCreate(customPasswordReset, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjPasswordResetCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CustomRegister} customRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjRegistrationCreate(customRegister: CustomRegister, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjRegistrationCreate(customRegister, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjRegistrationCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {ResendEmailVerification} resendEmailVerification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjRegistrationResendEmailCreate(resendEmailVerification: ResendEmailVerification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjRegistrationResendEmailCreate(resendEmailVerification, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjRegistrationResendEmailCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjRegistrationVerifyEmailCreate(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RestAuthDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjRegistrationVerifyEmailCreate(verifyEmail, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjRegistrationVerifyEmailCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {PatchedUserDetails} [patchedUserDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjUserPartialUpdate(patchedUserDetails?: PatchedUserDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjUserPartialUpdate(patchedUserDetails, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjUserPartialUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjUserRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjUserRetrieve(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjUserRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {UserDetails} [userDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authDjUserUpdate(userDetails?: UserDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authDjUserUpdate(userDetails, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authDjUserUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authUserMeRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authUserMeRetrieve(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.authUserMeRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRetailAccount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRetailAccount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.deleteRetailAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Login endpoint for retail customers in mobile app
         * @param {CompanyLogin} companyLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAsCompany(companyLogin: CompanyLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAsCompany(companyLogin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.loginAsCompany']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Login endpoint for retail customers in mobile app
         * @param {CustomerLogin} customerLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAsCustomer(customerLogin: CustomerLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAsCustomer(customerLogin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.loginAsCustomer']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AppleLogin} appleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithApple(appleLogin: AppleLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithApple(appleLogin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.loginWithApple']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GoogleLogin} googleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginWithGoogle(googleLogin: GoogleLogin, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JwtResponseView>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginWithGoogle(googleLogin, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.loginWithGoogle']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {NotificationPreferences} notificationPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async togglePushNotificationPreferences(notificationPreferences: NotificationPreferences, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.togglePushNotificationPreferences(notificationPreferences, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.togglePushNotificationPreferences']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {PatchedUpdateUserInfo} [patchedUpdateUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyUserDetails(patchedUpdateUserInfo?: PatchedUpdateUserInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyUserDetails(patchedUpdateUserInfo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.updateMyUserDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verifyJwtToken(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyJwtToken(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthApi.verifyJwtToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authCsrfRetrieve(options?: any): AxiosPromise<void> {
            return localVarFp.authCsrfRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
         * @param {CompanyLogin} companyLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjLoginCreate(companyLogin: CompanyLogin, options?: any): AxiosPromise<Token> {
            return localVarFp.authDjLoginCreate(companyLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjLogoutCreate(options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjLogoutCreate(options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordChange} passwordChange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjPasswordChangeCreate(passwordChange: PasswordChange, options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjPasswordChangeCreate(passwordChange, options).then((request) => request(axios, basePath));
        },
        /**
         * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
         * @param {PasswordResetConfirm} passwordResetConfirm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjPasswordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjPasswordResetConfirmCreate(passwordResetConfirm, options).then((request) => request(axios, basePath));
        },
        /**
         * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
         * @param {CustomPasswordReset} customPasswordReset 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjPasswordResetCreate(customPasswordReset: CustomPasswordReset, options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjPasswordResetCreate(customPasswordReset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomRegister} customRegister 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjRegistrationCreate(customRegister: CustomRegister, options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjRegistrationCreate(customRegister, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResendEmailVerification} resendEmailVerification 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjRegistrationResendEmailCreate(resendEmailVerification: ResendEmailVerification, options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjRegistrationResendEmailCreate(resendEmailVerification, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VerifyEmail} verifyEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjRegistrationVerifyEmailCreate(verifyEmail: VerifyEmail, options?: any): AxiosPromise<RestAuthDetail> {
            return localVarFp.authDjRegistrationVerifyEmailCreate(verifyEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {PatchedUserDetails} [patchedUserDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjUserPartialUpdate(patchedUserDetails?: PatchedUserDetails, options?: any): AxiosPromise<UserDetails> {
            return localVarFp.authDjUserPartialUpdate(patchedUserDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjUserRetrieve(options?: any): AxiosPromise<UserDetails> {
            return localVarFp.authDjUserRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
         * @param {UserDetails} [userDetails] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authDjUserUpdate(userDetails?: UserDetails, options?: any): AxiosPromise<UserDetails> {
            return localVarFp.authDjUserUpdate(userDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserMeRetrieve(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.authUserMeRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRetailAccount(options?: any): AxiosPromise<void> {
            return localVarFp.deleteRetailAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * Login endpoint for retail customers in mobile app
         * @param {CompanyLogin} companyLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsCompany(companyLogin: CompanyLogin, options?: any): AxiosPromise<JwtResponseView> {
            return localVarFp.loginAsCompany(companyLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * Login endpoint for retail customers in mobile app
         * @param {CustomerLogin} customerLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAsCustomer(customerLogin: CustomerLogin, options?: any): AxiosPromise<JwtResponseView> {
            return localVarFp.loginAsCustomer(customerLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AppleLogin} appleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithApple(appleLogin: AppleLogin, options?: any): AxiosPromise<JwtResponseView> {
            return localVarFp.loginWithApple(appleLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GoogleLogin} googleLogin 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginWithGoogle(googleLogin: GoogleLogin, options?: any): AxiosPromise<JwtResponseView> {
            return localVarFp.loginWithGoogle(googleLogin, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationPreferences} notificationPreferences 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        togglePushNotificationPreferences(notificationPreferences: NotificationPreferences, options?: any): AxiosPromise<void> {
            return localVarFp.togglePushNotificationPreferences(notificationPreferences, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PatchedUpdateUserInfo} [patchedUpdateUserInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyUserDetails(patchedUpdateUserInfo?: PatchedUpdateUserInfo, options?: any): AxiosPromise<UserInfo> {
            return localVarFp.updateMyUserDetails(patchedUpdateUserInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyJwtToken(options?: any): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.verifyJwtToken(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authCsrfRetrieve(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authCsrfRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Check the credentials and return the REST Token if the credentials are valid and authenticated. Calls Django Auth login method to register User ID in Django session framework  Accept the following POST parameters: username, password Return the REST Framework Token Object\'s key.
     * @param {CompanyLogin} companyLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjLoginCreate(companyLogin: CompanyLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjLoginCreate(companyLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django logout method and delete the Token object assigned to the current User object.  Accepts/Returns nothing.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjLogoutCreate(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjLogoutCreate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django Auth SetPasswordForm save method.  Accepts the following POST parameters: new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordChange} passwordChange 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjPasswordChangeCreate(passwordChange: PasswordChange, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjPasswordChangeCreate(passwordChange, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password reset e-mail link is confirmed, therefore this resets the user\'s password.  Accepts the following POST parameters: token, uid,     new_password1, new_password2 Returns the success/fail message.
     * @param {PasswordResetConfirm} passwordResetConfirm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjPasswordResetConfirmCreate(passwordResetConfirm: PasswordResetConfirm, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjPasswordResetConfirmCreate(passwordResetConfirm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calls Django Auth PasswordResetForm save method.  Accepts the following POST parameters: email Returns the success/fail message.
     * @param {CustomPasswordReset} customPasswordReset 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjPasswordResetCreate(customPasswordReset: CustomPasswordReset, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjPasswordResetCreate(customPasswordReset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomRegister} customRegister 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjRegistrationCreate(customRegister: CustomRegister, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjRegistrationCreate(customRegister, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResendEmailVerification} resendEmailVerification 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjRegistrationResendEmailCreate(resendEmailVerification: ResendEmailVerification, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjRegistrationResendEmailCreate(resendEmailVerification, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VerifyEmail} verifyEmail 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjRegistrationVerifyEmailCreate(verifyEmail: VerifyEmail, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjRegistrationVerifyEmailCreate(verifyEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {PatchedUserDetails} [patchedUserDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjUserPartialUpdate(patchedUserDetails?: PatchedUserDetails, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjUserPartialUpdate(patchedUserDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjUserRetrieve(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjUserRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads and updates UserModel fields Accepts GET, PUT, PATCH methods.  Default accepted fields: username, first_name, last_name Default display fields: pk, username, email, first_name, last_name Read-only fields: pk, email  Returns UserModel fields.
     * @param {UserDetails} [userDetails] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authDjUserUpdate(userDetails?: UserDetails, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authDjUserUpdate(userDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authUserMeRetrieve(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).authUserMeRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public deleteRetailAccount(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).deleteRetailAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login endpoint for retail customers in mobile app
     * @param {CompanyLogin} companyLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAsCompany(companyLogin: CompanyLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAsCompany(companyLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Login endpoint for retail customers in mobile app
     * @param {CustomerLogin} customerLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAsCustomer(customerLogin: CustomerLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAsCustomer(customerLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AppleLogin} appleLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginWithApple(appleLogin: AppleLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginWithApple(appleLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GoogleLogin} googleLogin 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginWithGoogle(googleLogin: GoogleLogin, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginWithGoogle(googleLogin, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationPreferences} notificationPreferences 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public togglePushNotificationPreferences(notificationPreferences: NotificationPreferences, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).togglePushNotificationPreferences(notificationPreferences, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PatchedUpdateUserInfo} [patchedUpdateUserInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public updateMyUserDetails(patchedUpdateUserInfo?: PatchedUpdateUserInfo, options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).updateMyUserDetails(patchedUpdateUserInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public verifyJwtToken(options?: RawAxiosRequestConfig) {
        return AuthApiFp(this.configuration).verifyJwtToken(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateBranchOffice} createBranchOffice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesCreate: async (createBranchOffice: CreateBranchOffice, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createBranchOffice' is not null or undefined
            assertParamExists('companyBranchOfficesCreate', 'createBranchOffice', createBranchOffice)
            const localVarPath = `/api/company/branch-offices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createBranchOffice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/branch-offices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesRetrieve: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyBranchOfficesRetrieve', 'id', id)
            const localVarPath = `/api/company/branch-offices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesUploadCoverImageCreate: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('companyBranchOfficesUploadCoverImageCreate', 'id', id)
            const localVarPath = `/api/company/branch-offices/{id}/upload-cover-image/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows to start integration with Stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStripeDashboardRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/stripe-dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyDetails: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ToggleOperationDto} toggleOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleBranchOfficeOperativity: async (id: string, toggleOperationDto: ToggleOperationDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('toggleBranchOfficeOperativity', 'id', id)
            // verify required parameter 'toggleOperationDto' is not null or undefined
            assertParamExists('toggleBranchOfficeOperativity', 'toggleOperationDto', toggleOperationDto)
            const localVarPath = `/api/company/branch-offices/{id}/toggle-operation/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleOperationDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedCreateBranchOffice} [patchedCreateBranchOffice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchOffice: async (id: string, patchedCreateBranchOffice?: PatchedCreateBranchOffice, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBranchOffice', 'id', id)
            const localVarPath = `/api/company/branch-offices/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedCreateBranchOffice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows updates of company data to admins
         * @param {PatchedUpdateCompanyInfo} [patchedUpdateCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyData: async (patchedUpdateCompanyInfo?: PatchedUpdateCompanyInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/details-admin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateCompanyInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Allows updates of company data to admins
         * @param {PatchedInitCompanyInfo} [patchedInitCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInitialCompanyData: async (patchedInitCompanyInfo?: PatchedInitCompanyInfo, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/company-verification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedInitCompanyInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {PatchedUpdateBranchOfficeLocation} [patchedUpdateBranchOfficeLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation: async (branchOfficeId: number, patchedUpdateBranchOfficeLocation?: PatchedUpdateBranchOfficeLocation, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('updateLocation', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/company/branch-offices/{branchOfficeId}/location/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateBranchOfficeLocation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates opening hours
         * @param {number} openingHoursId 
         * @param {PatchedUpdateOpeningHoursDto} [patchedUpdateOpeningHoursDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpeningHours: async (openingHoursId: number, patchedUpdateOpeningHoursDto?: PatchedUpdateOpeningHoursDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'openingHoursId' is not null or undefined
            assertParamExists('updateOpeningHours', 'openingHoursId', openingHoursId)
            const localVarPath = `/api/company/branch-office/opening-hours/{openingHoursId}/`
                .replace(`{${"openingHoursId"}}`, encodeURIComponent(String(openingHoursId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateOpeningHoursDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateBranchOffice} createBranchOffice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyBranchOfficesCreate(createBranchOffice: CreateBranchOffice, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchOfficeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyBranchOfficesCreate(createBranchOffice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.companyBranchOfficesCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyBranchOfficesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BranchOfficeDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyBranchOfficesList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.companyBranchOfficesList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyBranchOfficesRetrieve(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchOfficeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyBranchOfficesRetrieve(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.companyBranchOfficesRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyBranchOfficesUploadCoverImageCreate(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyBranchOfficesUploadCoverImageCreate(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.companyBranchOfficesUploadCoverImageCreate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Allows to start integration with Stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyStripeDashboardRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StripeDashboardUrl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyStripeDashboardRetrieve(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.companyStripeDashboardRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyDetails(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyDetails(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.getCompanyDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {ToggleOperationDto} toggleOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleBranchOfficeOperativity(id: string, toggleOperationDto: ToggleOperationDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleBranchOfficeOperativity(id, toggleOperationDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.toggleBranchOfficeOperativity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedCreateBranchOffice} [patchedCreateBranchOffice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranchOffice(id: string, patchedCreateBranchOffice?: PatchedCreateBranchOffice, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranchOffice(id, patchedCreateBranchOffice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.updateBranchOffice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Allows updates of company data to admins
         * @param {PatchedUpdateCompanyInfo} [patchedUpdateCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCompanyData(patchedUpdateCompanyInfo?: PatchedUpdateCompanyInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCompanyData(patchedUpdateCompanyInfo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.updateCompanyData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @summary Allows updates of company data to admins
         * @param {PatchedInitCompanyInfo} [patchedInitCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInitialCompanyData(patchedInitCompanyInfo?: PatchedInitCompanyInfo, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInitialCompanyData(patchedInitCompanyInfo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.updateInitialCompanyData']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {PatchedUpdateBranchOfficeLocation} [patchedUpdateBranchOfficeLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLocation(branchOfficeId: number, patchedUpdateBranchOfficeLocation?: PatchedUpdateBranchOfficeLocation, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocation(branchOfficeId, patchedUpdateBranchOfficeLocation, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.updateLocation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates opening hours
         * @param {number} openingHoursId 
         * @param {PatchedUpdateOpeningHoursDto} [patchedUpdateOpeningHoursDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateOpeningHours(openingHoursId: number, patchedUpdateOpeningHoursDto?: PatchedUpdateOpeningHoursDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateOpeningHours(openingHoursId, patchedUpdateOpeningHoursDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyApi.updateOpeningHours']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateBranchOffice} createBranchOffice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesCreate(createBranchOffice: CreateBranchOffice, options?: any): AxiosPromise<BranchOfficeDetails> {
            return localVarFp.companyBranchOfficesCreate(createBranchOffice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesList(options?: any): AxiosPromise<Array<BranchOfficeDetails>> {
            return localVarFp.companyBranchOfficesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesRetrieve(id: string, options?: any): AxiosPromise<BranchOfficeDetails> {
            return localVarFp.companyBranchOfficesRetrieve(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyBranchOfficesUploadCoverImageCreate(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.companyBranchOfficesUploadCoverImageCreate(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows to start integration with Stripe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyStripeDashboardRetrieve(options?: any): AxiosPromise<StripeDashboardUrl> {
            return localVarFp.companyStripeDashboardRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyDetails(options?: any): AxiosPromise<CompanyUser> {
            return localVarFp.getCompanyDetails(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ToggleOperationDto} toggleOperationDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleBranchOfficeOperativity(id: string, toggleOperationDto: ToggleOperationDto, options?: any): AxiosPromise<void> {
            return localVarFp.toggleBranchOfficeOperativity(id, toggleOperationDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PatchedCreateBranchOffice} [patchedCreateBranchOffice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranchOffice(id: string, patchedCreateBranchOffice?: PatchedCreateBranchOffice, options?: any): AxiosPromise<void> {
            return localVarFp.updateBranchOffice(id, patchedCreateBranchOffice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows updates of company data to admins
         * @param {PatchedUpdateCompanyInfo} [patchedUpdateCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCompanyData(patchedUpdateCompanyInfo?: PatchedUpdateCompanyInfo, options?: any): AxiosPromise<CompanyDetails> {
            return localVarFp.updateCompanyData(patchedUpdateCompanyInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Allows updates of company data to admins
         * @param {PatchedInitCompanyInfo} [patchedInitCompanyInfo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInitialCompanyData(patchedInitCompanyInfo?: PatchedInitCompanyInfo, options?: any): AxiosPromise<CompanyDetails> {
            return localVarFp.updateInitialCompanyData(patchedInitCompanyInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {PatchedUpdateBranchOfficeLocation} [patchedUpdateBranchOfficeLocation] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLocation(branchOfficeId: number, patchedUpdateBranchOfficeLocation?: PatchedUpdateBranchOfficeLocation, options?: any): AxiosPromise<void> {
            return localVarFp.updateLocation(branchOfficeId, patchedUpdateBranchOfficeLocation, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates opening hours
         * @param {number} openingHoursId 
         * @param {PatchedUpdateOpeningHoursDto} [patchedUpdateOpeningHoursDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOpeningHours(openingHoursId: number, patchedUpdateOpeningHoursDto?: PatchedUpdateOpeningHoursDto, options?: any): AxiosPromise<void> {
            return localVarFp.updateOpeningHours(openingHoursId, patchedUpdateOpeningHoursDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {CreateBranchOffice} createBranchOffice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyBranchOfficesCreate(createBranchOffice: CreateBranchOffice, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyBranchOfficesCreate(createBranchOffice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyBranchOfficesList(options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyBranchOfficesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyBranchOfficesRetrieve(id: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyBranchOfficesRetrieve(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyBranchOfficesUploadCoverImageCreate(id: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyBranchOfficesUploadCoverImageCreate(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows to start integration with Stripe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyStripeDashboardRetrieve(options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyStripeDashboardRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public getCompanyDetails(options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).getCompanyDetails(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {ToggleOperationDto} toggleOperationDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public toggleBranchOfficeOperativity(id: string, toggleOperationDto: ToggleOperationDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).toggleBranchOfficeOperativity(id, toggleOperationDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PatchedCreateBranchOffice} [patchedCreateBranchOffice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateBranchOffice(id: string, patchedCreateBranchOffice?: PatchedCreateBranchOffice, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateBranchOffice(id, patchedCreateBranchOffice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows updates of company data to admins
     * @param {PatchedUpdateCompanyInfo} [patchedUpdateCompanyInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateCompanyData(patchedUpdateCompanyInfo?: PatchedUpdateCompanyInfo, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateCompanyData(patchedUpdateCompanyInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Allows updates of company data to admins
     * @param {PatchedInitCompanyInfo} [patchedInitCompanyInfo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateInitialCompanyData(patchedInitCompanyInfo?: PatchedInitCompanyInfo, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateInitialCompanyData(patchedInitCompanyInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} branchOfficeId 
     * @param {PatchedUpdateBranchOfficeLocation} [patchedUpdateBranchOfficeLocation] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateLocation(branchOfficeId: number, patchedUpdateBranchOfficeLocation?: PatchedUpdateBranchOfficeLocation, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateLocation(branchOfficeId, patchedUpdateBranchOfficeLocation, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates opening hours
     * @param {number} openingHoursId 
     * @param {PatchedUpdateOpeningHoursDto} [patchedUpdateOpeningHoursDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public updateOpeningHours(openingHoursId: number, patchedUpdateOpeningHoursDto?: PatchedUpdateOpeningHoursDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).updateOpeningHours(openingHoursId, patchedUpdateOpeningHoursDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyMembersApi - axios parameter creator
 * @export
 */
export const CompanyMembersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddMember} addMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyMember: async (addMember: AddMember, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addMember' is not null or undefined
            assertParamExists('addCompanyMember', 'addMember', addMember)
            const localVarPath = `/api/company/members/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addMember, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyMembersDestroy: async (memberId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('companyMembersDestroy', 'memberId', memberId)
            const localVarPath = `/api/company/members/{memberId}/`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} invitationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInfo: async (invitationCode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'invitationCode' is not null or undefined
            assertParamExists('getCompanyInfo', 'invitationCode', invitationCode)
            const localVarPath = `/api/company/invitation/company-info/{invitationCode}`
                .replace(`{${"invitationCode"}}`, encodeURIComponent(String(invitationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMembers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/members/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyMembersApi - functional programming interface
 * @export
 */
export const CompanyMembersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyMembersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddMember} addMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyMember(addMember: AddMember, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyMember(addMember, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyMembersApi.addCompanyMember']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyMembersDestroy(memberId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyMembersDestroy(memberId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyMembersApi.companyMembersDestroy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} invitationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyInfo(invitationCode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InvitationCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyInfo(invitationCode, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyMembersApi.getCompanyInfo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyMembers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyMember>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyMembers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CompanyMembersApi.getCompanyMembers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CompanyMembersApi - factory interface
 * @export
 */
export const CompanyMembersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyMembersApiFp(configuration)
    return {
        /**
         * 
         * @param {AddMember} addMember 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyMember(addMember: AddMember, options?: any): AxiosPromise<void> {
            return localVarFp.addCompanyMember(addMember, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} memberId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyMembersDestroy(memberId: number, options?: any): AxiosPromise<void> {
            return localVarFp.companyMembersDestroy(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} invitationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyInfo(invitationCode: string, options?: any): AxiosPromise<InvitationCompany> {
            return localVarFp.getCompanyInfo(invitationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyMembers(options?: any): AxiosPromise<Array<CompanyMember>> {
            return localVarFp.getCompanyMembers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyMembersApi - object-oriented interface
 * @export
 * @class CompanyMembersApi
 * @extends {BaseAPI}
 */
export class CompanyMembersApi extends BaseAPI {
    /**
     * 
     * @param {AddMember} addMember 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyMembersApi
     */
    public addCompanyMember(addMember: AddMember, options?: RawAxiosRequestConfig) {
        return CompanyMembersApiFp(this.configuration).addCompanyMember(addMember, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} memberId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyMembersApi
     */
    public companyMembersDestroy(memberId: number, options?: RawAxiosRequestConfig) {
        return CompanyMembersApiFp(this.configuration).companyMembersDestroy(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} invitationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyMembersApi
     */
    public getCompanyInfo(invitationCode: string, options?: RawAxiosRequestConfig) {
        return CompanyMembersApiFp(this.configuration).getCompanyInfo(invitationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyMembersApi
     */
    public getCompanyMembers(options?: RawAxiosRequestConfig) {
        return CompanyMembersApiFp(this.configuration).getCompanyMembers(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomerFavoritesApi - axios parameter creator
 * @export
 */
export const CustomerFavoritesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add a new favorite branch office
         * @param {ToggleFavoriteDto} toggleFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavorite: async (toggleFavoriteDto: ToggleFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toggleFavoriteDto' is not null or undefined
            assertParamExists('addFavorite', 'toggleFavoriteDto', toggleFavoriteDto)
            const localVarPath = `/api/company/customer/favorites/add/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists all operational branch offices for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteBranchOffices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/company/customer/favorites/branch-offices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove favorite branch office
         * @param {ToggleFavoriteDto} toggleFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFavorite: async (toggleFavoriteDto: ToggleFavoriteDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'toggleFavoriteDto' is not null or undefined
            assertParamExists('removeFavorite', 'toggleFavoriteDto', toggleFavoriteDto)
            const localVarPath = `/api/company/customer/favorites/remove/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(toggleFavoriteDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerFavoritesApi - functional programming interface
 * @export
 */
export const CustomerFavoritesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerFavoritesApiAxiosParamCreator(configuration)
    return {
        /**
         * Add a new favorite branch office
         * @param {ToggleFavoriteDto} toggleFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavorite(toggleFavoriteDto: ToggleFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavorite(toggleFavoriteDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerFavoritesApi.addFavorite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Lists all operational branch offices for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteBranchOffices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BranchOfficeDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteBranchOffices(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerFavoritesApi.getFavoriteBranchOffices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Remove favorite branch office
         * @param {ToggleFavoriteDto} toggleFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFavorite(toggleFavoriteDto: ToggleFavoriteDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFavorite(toggleFavoriteDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomerFavoritesApi.removeFavorite']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomerFavoritesApi - factory interface
 * @export
 */
export const CustomerFavoritesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerFavoritesApiFp(configuration)
    return {
        /**
         * Add a new favorite branch office
         * @param {ToggleFavoriteDto} toggleFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavorite(toggleFavoriteDto: ToggleFavoriteDto, options?: any): AxiosPromise<void> {
            return localVarFp.addFavorite(toggleFavoriteDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists all operational branch offices for user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteBranchOffices(options?: any): AxiosPromise<Array<BranchOfficeDetails>> {
            return localVarFp.getFavoriteBranchOffices(options).then((request) => request(axios, basePath));
        },
        /**
         * Remove favorite branch office
         * @param {ToggleFavoriteDto} toggleFavoriteDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFavorite(toggleFavoriteDto: ToggleFavoriteDto, options?: any): AxiosPromise<void> {
            return localVarFp.removeFavorite(toggleFavoriteDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerFavoritesApi - object-oriented interface
 * @export
 * @class CustomerFavoritesApi
 * @extends {BaseAPI}
 */
export class CustomerFavoritesApi extends BaseAPI {
    /**
     * Add a new favorite branch office
     * @param {ToggleFavoriteDto} toggleFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFavoritesApi
     */
    public addFavorite(toggleFavoriteDto: ToggleFavoriteDto, options?: RawAxiosRequestConfig) {
        return CustomerFavoritesApiFp(this.configuration).addFavorite(toggleFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists all operational branch offices for user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFavoritesApi
     */
    public getFavoriteBranchOffices(options?: RawAxiosRequestConfig) {
        return CustomerFavoritesApiFp(this.configuration).getFavoriteBranchOffices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove favorite branch office
     * @param {ToggleFavoriteDto} toggleFavoriteDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerFavoritesApi
     */
    public removeFavorite(toggleFavoriteDto: ToggleFavoriteDto, options?: RawAxiosRequestConfig) {
        return CustomerFavoritesApiFp(this.configuration).removeFavorite(toggleFavoriteDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersBranchOfficeApi - axios parameter creator
 * @export
 */
export const CustomersBranchOfficeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOfficeDetailsPublic: async (branchOfficeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('getBranchOfficeDetailsPublic', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/company/customer/branch-office/{branchOfficeId}/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersBranchOfficeApi - functional programming interface
 * @export
 */
export const CustomersBranchOfficeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersBranchOfficeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchOfficeDetailsPublic(branchOfficeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchOfficeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchOfficeDetailsPublic(branchOfficeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersBranchOfficeApi.getBranchOfficeDetailsPublic']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomersBranchOfficeApi - factory interface
 * @export
 */
export const CustomersBranchOfficeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersBranchOfficeApiFp(configuration)
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOfficeDetailsPublic(branchOfficeId: number, options?: any): AxiosPromise<BranchOfficeDetails> {
            return localVarFp.getBranchOfficeDetailsPublic(branchOfficeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersBranchOfficeApi - object-oriented interface
 * @export
 * @class CustomersBranchOfficeApi
 * @extends {BaseAPI}
 */
export class CustomersBranchOfficeApi extends BaseAPI {
    /**
     * 
     * @param {number} branchOfficeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersBranchOfficeApi
     */
    public getBranchOfficeDetailsPublic(branchOfficeId: number, options?: RawAxiosRequestConfig) {
        return CustomersBranchOfficeApiFp(this.configuration).getBranchOfficeDetailsPublic(branchOfficeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersBranchOfficeGuestApi - axios parameter creator
 * @export
 */
export const CustomersBranchOfficeGuestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicBranchOfficeDetails: async (branchOfficeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('getPublicBranchOfficeDetails', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/company/customer/branch-office-guest/{branchOfficeId}/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersBranchOfficeGuestApi - functional programming interface
 * @export
 */
export const CustomersBranchOfficeGuestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersBranchOfficeGuestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublicBranchOfficeDetails(branchOfficeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchOfficeDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublicBranchOfficeDetails(branchOfficeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersBranchOfficeGuestApi.getPublicBranchOfficeDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomersBranchOfficeGuestApi - factory interface
 * @export
 */
export const CustomersBranchOfficeGuestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersBranchOfficeGuestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublicBranchOfficeDetails(branchOfficeId: number, options?: any): AxiosPromise<BranchOfficeDetails> {
            return localVarFp.getPublicBranchOfficeDetails(branchOfficeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersBranchOfficeGuestApi - object-oriented interface
 * @export
 * @class CustomersBranchOfficeGuestApi
 * @extends {BaseAPI}
 */
export class CustomersBranchOfficeGuestApi extends BaseAPI {
    /**
     * 
     * @param {number} branchOfficeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersBranchOfficeGuestApi
     */
    public getPublicBranchOfficeDetails(branchOfficeId: number, options?: RawAxiosRequestConfig) {
        return CustomersBranchOfficeGuestApiFp(this.configuration).getPublicBranchOfficeDetails(branchOfficeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersShopApi - axios parameter creator
 * @export
 */
export const CustomersShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddToCartDto} addToCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCart: async (addToCartDto: AddToCartDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addToCartDto' is not null or undefined
            assertParamExists('addToCart', 'addToCartDto', addToCartDto)
            const localVarPath = `/api/shop/customer/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addToCartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks order as collected.  If order does not exist, does not belong to you or has been collected. Status 400 will be returned.
         * @param {string} orderUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectOrder: async (orderUid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderUid' is not null or undefined
            assertParamExists('collectOrder', 'orderUid', orderUid)
            const localVarPath = `/api/shop/customer/collect-order/{orderUid}`
                .replace(`{${"orderUid"}}`, encodeURIComponent(String(orderUid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/create-order/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches orders which were reserved and are waiting for collection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoughtOrdersForUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/active-orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartDisplay: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryForUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/order-history/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProducts2: async (branchOfficeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('getPublishedProducts2', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/shop/customer/branch-offices/{branchOfficeId}/products/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromCart: async (productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('removeFromCart', 'productId', productId)
            const localVarPath = `/api/shop/customer/cart-items/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCustomerBranchOfficesList: async (latitude?: number, longitude?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/branch-offices/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCustomerCartDestroy: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersShopApi - functional programming interface
 * @export
 */
export const CustomersShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddToCartDto} addToCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addToCart(addToCartDto: AddToCartDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addToCart(addToCartDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.addToCart']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Marks order as collected.  If order does not exist, does not belong to you or has been collected. Status 400 will be returned.
         * @param {string} orderUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async collectOrder(orderUid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.collectOrder(orderUid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.collectOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrder(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.createOrder']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches orders which were reserved and are waiting for collection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBoughtOrdersForUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDisplayDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBoughtOrdersForUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.getBoughtOrdersForUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCartDisplay(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCartDisplay(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.getCartDisplay']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrderHistoryForUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDisplayDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrderHistoryForUser(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.getOrderHistoryForUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedProducts2(branchOfficeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerProductDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedProducts2(branchOfficeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.getPublishedProducts2']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeFromCart(productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeFromCart(productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.removeFromCart']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopCustomerBranchOfficesList(latitude?: number, longitude?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicBranchOffice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopCustomerBranchOfficesList(latitude, longitude, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.shopCustomerBranchOfficesList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopCustomerCartDestroy(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopCustomerCartDestroy(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopApi.shopCustomerCartDestroy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomersShopApi - factory interface
 * @export
 */
export const CustomersShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersShopApiFp(configuration)
    return {
        /**
         * 
         * @param {AddToCartDto} addToCartDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addToCart(addToCartDto: AddToCartDto, options?: any): AxiosPromise<void> {
            return localVarFp.addToCart(addToCartDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks order as collected.  If order does not exist, does not belong to you or has been collected. Status 400 will be returned.
         * @param {string} orderUid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        collectOrder(orderUid: string, options?: any): AxiosPromise<void> {
            return localVarFp.collectOrder(orderUid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrder(options?: any): AxiosPromise<void> {
            return localVarFp.createOrder(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches orders which were reserved and are waiting for collection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBoughtOrdersForUser(options?: any): AxiosPromise<Array<OrderDisplayDto>> {
            return localVarFp.getBoughtOrdersForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCartDisplay(options?: any): AxiosPromise<CartDto> {
            return localVarFp.getCartDisplay(options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches order history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderHistoryForUser(options?: any): AxiosPromise<Array<OrderDisplayDto>> {
            return localVarFp.getOrderHistoryForUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProducts2(branchOfficeId: number, options?: any): AxiosPromise<Array<CustomerProductDetailsDto>> {
            return localVarFp.getPublishedProducts2(branchOfficeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeFromCart(productId: number, options?: any): AxiosPromise<void> {
            return localVarFp.removeFromCart(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCustomerBranchOfficesList(latitude?: number, longitude?: number, options?: any): AxiosPromise<Array<PublicBranchOffice>> {
            return localVarFp.shopCustomerBranchOfficesList(latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopCustomerCartDestroy(options?: any): AxiosPromise<void> {
            return localVarFp.shopCustomerCartDestroy(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersShopApi - object-oriented interface
 * @export
 * @class CustomersShopApi
 * @extends {BaseAPI}
 */
export class CustomersShopApi extends BaseAPI {
    /**
     * 
     * @param {AddToCartDto} addToCartDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public addToCart(addToCartDto: AddToCartDto, options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).addToCart(addToCartDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks order as collected.  If order does not exist, does not belong to you or has been collected. Status 400 will be returned.
     * @param {string} orderUid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public collectOrder(orderUid: string, options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).collectOrder(orderUid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public createOrder(options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).createOrder(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches orders which were reserved and are waiting for collection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public getBoughtOrdersForUser(options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).getBoughtOrdersForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public getCartDisplay(options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).getCartDisplay(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches order history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public getOrderHistoryForUser(options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).getOrderHistoryForUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} branchOfficeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public getPublishedProducts2(branchOfficeId: number, options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).getPublishedProducts2(branchOfficeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public removeFromCart(productId: number, options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).removeFromCart(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [latitude] 
     * @param {number} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public shopCustomerBranchOfficesList(latitude?: number, longitude?: number, options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).shopCustomerBranchOfficesList(latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopApi
     */
    public shopCustomerCartDestroy(options?: RawAxiosRequestConfig) {
        return CustomersShopApiFp(this.configuration).shopCustomerCartDestroy(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CustomersShopGuestApi - axios parameter creator
 * @export
 */
export const CustomersShopGuestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOfficesForGuests: async (latitude?: number, longitude?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/customer/branch-offices-guest/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProducts: async (branchOfficeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('getPublishedProducts', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/shop/customer/branch-offices-guest/{branchOfficeId}/products/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomersShopGuestApi - functional programming interface
 * @export
 */
export const CustomersShopGuestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomersShopGuestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBranchOfficesForGuests(latitude?: number, longitude?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PublicBranchOffice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchOfficesForGuests(latitude, longitude, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopGuestApi.getBranchOfficesForGuests']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPublishedProducts(branchOfficeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CustomerProductDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPublishedProducts(branchOfficeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CustomersShopGuestApi.getPublishedProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CustomersShopGuestApi - factory interface
 * @export
 */
export const CustomersShopGuestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomersShopGuestApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [latitude] 
         * @param {number} [longitude] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranchOfficesForGuests(latitude?: number, longitude?: number, options?: any): AxiosPromise<Array<PublicBranchOffice>> {
            return localVarFp.getBranchOfficesForGuests(latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPublishedProducts(branchOfficeId: number, options?: any): AxiosPromise<Array<CustomerProductDetailsDto>> {
            return localVarFp.getPublishedProducts(branchOfficeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomersShopGuestApi - object-oriented interface
 * @export
 * @class CustomersShopGuestApi
 * @extends {BaseAPI}
 */
export class CustomersShopGuestApi extends BaseAPI {
    /**
     * 
     * @param {number} [latitude] 
     * @param {number} [longitude] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopGuestApi
     */
    public getBranchOfficesForGuests(latitude?: number, longitude?: number, options?: RawAxiosRequestConfig) {
        return CustomersShopGuestApiFp(this.configuration).getBranchOfficesForGuests(latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} branchOfficeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomersShopGuestApi
     */
    public getPublishedProducts(branchOfficeId: number, options?: RawAxiosRequestConfig) {
        return CustomersShopGuestApiFp(this.configuration).getPublishedProducts(branchOfficeId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HealthcheckApi - axios parameter creator
 * @export
 */
export const HealthcheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/healthcheck/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthcheckApi - functional programming interface
 * @export
 */
export const HealthcheckApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthcheckApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthcheckRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthcheckRetrieve(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['HealthcheckApi.healthcheckRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * HealthcheckApi - factory interface
 * @export
 */
export const HealthcheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthcheckApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthcheckRetrieve(options?: any): AxiosPromise<void> {
            return localVarFp.healthcheckRetrieve(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthcheckApi - object-oriented interface
 * @export
 * @class HealthcheckApi
 * @extends {BaseAPI}
 */
export class HealthcheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthcheckApi
     */
    public healthcheckRetrieve(options?: RawAxiosRequestConfig) {
        return HealthcheckApiFp(this.configuration).healthcheckRetrieve(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDeviceToken} createDeviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceToken: async (createDeviceToken: CreateDeviceToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeviceToken' is not null or undefined
            assertParamExists('createDeviceToken', 'createDeviceToken', createDeviceToken)
            const localVarPath = `/api/notifications/create-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeviceToken, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppNotifications: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notifications/in-app-notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDeviceToken} createDeviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDeviceToken(createDeviceToken: CreateDeviceToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDeviceToken(createDeviceToken, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationsApi.createDeviceToken']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInAppNotifications(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActiveInAppNotification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInAppNotifications(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationsApi.getInAppNotifications']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {CreateDeviceToken} createDeviceToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeviceToken(createDeviceToken: CreateDeviceToken, options?: any): AxiosPromise<void> {
            return localVarFp.createDeviceToken(createDeviceToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInAppNotifications(options?: any): AxiosPromise<Array<ActiveInAppNotification>> {
            return localVarFp.getInAppNotifications(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {CreateDeviceToken} createDeviceToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createDeviceToken(createDeviceToken: CreateDeviceToken, options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createDeviceToken(createDeviceToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getInAppNotifications(options?: RawAxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getInAppNotifications(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Changes default quantity of published products
         * @param {number} publishedProductId 
         * @param {PatchedChangeDefaultQuantityDto} [patchedChangeDefaultQuantityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDefaultQuantity: async (publishedProductId: number, patchedChangeDefaultQuantityDto?: PatchedChangeDefaultQuantityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishedProductId' is not null or undefined
            assertParamExists('changeDefaultQuantity', 'publishedProductId', publishedProductId)
            const localVarPath = `/api/shop/products/published/{publishedProductId}/change-default-quantity/`
                .replace(`{${"publishedProductId"}}`, encodeURIComponent(String(publishedProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedChangeDefaultQuantityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes quantity of published products
         * @param {number} publishedProductId 
         * @param {PatchedChangeQuantityDto} [patchedChangeQuantityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuantity: async (publishedProductId: number, patchedChangeQuantityDto?: PatchedChangeQuantityDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publishedProductId' is not null or undefined
            assertParamExists('changeQuantity', 'publishedProductId', publishedProductId)
            const localVarPath = `/api/shop/products/published/{publishedProductId}/change-quantity/`
                .replace(`{${"publishedProductId"}}`, encodeURIComponent(String(publishedProductId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedChangeQuantityDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new product
         * @summary Creates a new product
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryProduct: async (createProductDto: CreateProductDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProductDto' is not null or undefined
            assertParamExists('createInventoryProduct', 'createProductDto', createProductDto)
            const localVarPath = `/api/shop/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a product
         * @summary Fetches details about a product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSingleProduct: async (productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('fetchSingleProduct', 'productId', productId)
            const localVarPath = `/api/shop/products/{productId}/`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetches orders which were bought and are waiting for collection
         * @param {number} branchOfficeId 
         * @param {string} [collectionId] 
         * @param {string} [filterFrom] 
         * @param {string} [filterTo] 
         * @param {Array<GetAllOrdersOrderStatusEnum>} [orderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders: async (branchOfficeId: number, collectionId?: string, filterFrom?: string, filterTo?: string, orderStatus?: Array<GetAllOrdersOrderStatusEnum>, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('getAllOrders', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/shop/all-orders/{branchOfficeId}/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (collectionId !== undefined) {
                localVarQueryParameter['collection_id'] = collectionId;
            }

            if (filterFrom !== undefined) {
                localVarQueryParameter['filter_from'] = (filterFrom as any instanceof Date) ?
                    (filterFrom as any).toISOString().substring(0,10) :
                    filterFrom;
            }

            if (filterTo !== undefined) {
                localVarQueryParameter['filter_to'] = (filterTo as any instanceof Date) ?
                    (filterTo as any).toISOString().substring(0,10) :
                    filterTo;
            }

            if (orderStatus) {
                localVarQueryParameter['order_status'] = orderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all products in a company if user is admin. Otherwise only products in branch office are listed
         * @summary Returns products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryProducts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shop/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Validates original and discounted price. Helpful to clients
         * @param {number} discount 
         * @param {number} ogPrice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopPricingRetrieve: async (discount: number, ogPrice: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'discount' is not null or undefined
            assertParamExists('shopPricingRetrieve', 'discount', discount)
            // verify required parameter 'ogPrice' is not null or undefined
            assertParamExists('shopPricingRetrieve', 'ogPrice', ogPrice)
            const localVarPath = `/api/shop/pricing/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (discount !== undefined) {
                localVarQueryParameter['discount'] = discount;
            }

            if (ogPrice !== undefined) {
                localVarQueryParameter['og_price'] = ogPrice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deletes a product
         * @summary Deletes a product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopProductsDestroy: async (productId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('shopProductsDestroy', 'productId', productId)
            const localVarPath = `/api/shop/products/{productId}/`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a product
         * @summary Updates a product
         * @param {number} productId 
         * @param {PatchedUpdateProductDto} [patchedUpdateProductDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopProductsPartialUpdate: async (productId: number, patchedUpdateProductDto?: PatchedUpdateProductDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('shopProductsPartialUpdate', 'productId', productId)
            const localVarPath = `/api/shop/products/{productId}/`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateProductDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * Changes default quantity of published products
         * @param {number} publishedProductId 
         * @param {PatchedChangeDefaultQuantityDto} [patchedChangeDefaultQuantityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeDefaultQuantity(publishedProductId: number, patchedChangeDefaultQuantityDto?: PatchedChangeDefaultQuantityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeDefaultQuantity(publishedProductId, patchedChangeDefaultQuantityDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.changeDefaultQuantity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Changes quantity of published products
         * @param {number} publishedProductId 
         * @param {PatchedChangeQuantityDto} [patchedChangeQuantityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async changeQuantity(publishedProductId: number, patchedChangeQuantityDto?: PatchedChangeQuantityDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.changeQuantity(publishedProductId, patchedChangeQuantityDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.changeQuantity']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Creates a new product
         * @summary Creates a new product
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInventoryProduct(createProductDto: CreateProductDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInventoryProduct(createProductDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.createInventoryProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates a product
         * @summary Fetches details about a product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchSingleProduct(productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchSingleProduct(productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.fetchSingleProduct']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Fetches orders which were bought and are waiting for collection
         * @param {number} branchOfficeId 
         * @param {string} [collectionId] 
         * @param {string} [filterFrom] 
         * @param {string} [filterTo] 
         * @param {Array<GetAllOrdersOrderStatusEnum>} [orderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllOrders(branchOfficeId: number, collectionId?: string, filterFrom?: string, filterTo?: string, orderStatus?: Array<GetAllOrdersOrderStatusEnum>, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderDisplayDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOrders(branchOfficeId, collectionId, filterFrom, filterTo, orderStatus, page, pageSize, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.getAllOrders']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Returns all products in a company if user is admin. Otherwise only products in branch office are listed
         * @summary Returns products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInventoryProducts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDetailsDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInventoryProducts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.getInventoryProducts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Validates original and discounted price. Helpful to clients
         * @param {number} discount 
         * @param {number} ogPrice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopPricingRetrieve(discount: number, ogPrice: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricingCalculationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopPricingRetrieve(discount, ogPrice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.shopPricingRetrieve']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * deletes a product
         * @summary Deletes a product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopProductsDestroy(productId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopProductsDestroy(productId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.shopProductsDestroy']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * Updates a product
         * @summary Updates a product
         * @param {number} productId 
         * @param {PatchedUpdateProductDto} [patchedUpdateProductDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shopProductsPartialUpdate(productId: number, patchedUpdateProductDto?: PatchedUpdateProductDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shopProductsPartialUpdate(productId, patchedUpdateProductDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ShopApi.shopProductsPartialUpdate']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * Changes default quantity of published products
         * @param {number} publishedProductId 
         * @param {PatchedChangeDefaultQuantityDto} [patchedChangeDefaultQuantityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeDefaultQuantity(publishedProductId: number, patchedChangeDefaultQuantityDto?: PatchedChangeDefaultQuantityDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeDefaultQuantity(publishedProductId, patchedChangeDefaultQuantityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Changes quantity of published products
         * @param {number} publishedProductId 
         * @param {PatchedChangeQuantityDto} [patchedChangeQuantityDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeQuantity(publishedProductId: number, patchedChangeQuantityDto?: PatchedChangeQuantityDto, options?: any): AxiosPromise<void> {
            return localVarFp.changeQuantity(publishedProductId, patchedChangeQuantityDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new product
         * @summary Creates a new product
         * @param {CreateProductDto} createProductDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInventoryProduct(createProductDto: CreateProductDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.createInventoryProduct(createProductDto, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a product
         * @summary Fetches details about a product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchSingleProduct(productId: number, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.fetchSingleProduct(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetches orders which were bought and are waiting for collection
         * @param {number} branchOfficeId 
         * @param {string} [collectionId] 
         * @param {string} [filterFrom] 
         * @param {string} [filterTo] 
         * @param {Array<GetAllOrdersOrderStatusEnum>} [orderStatus] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrders(branchOfficeId: number, collectionId?: string, filterFrom?: string, filterTo?: string, orderStatus?: Array<GetAllOrdersOrderStatusEnum>, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<OrderDisplayDto>> {
            return localVarFp.getAllOrders(branchOfficeId, collectionId, filterFrom, filterTo, orderStatus, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all products in a company if user is admin. Otherwise only products in branch office are listed
         * @summary Returns products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInventoryProducts(options?: any): AxiosPromise<Array<ProductDetailsDto>> {
            return localVarFp.getInventoryProducts(options).then((request) => request(axios, basePath));
        },
        /**
         * Validates original and discounted price. Helpful to clients
         * @param {number} discount 
         * @param {number} ogPrice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopPricingRetrieve(discount: number, ogPrice: number, options?: any): AxiosPromise<PricingCalculationResponse> {
            return localVarFp.shopPricingRetrieve(discount, ogPrice, options).then((request) => request(axios, basePath));
        },
        /**
         * deletes a product
         * @summary Deletes a product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopProductsDestroy(productId: number, options?: any): AxiosPromise<void> {
            return localVarFp.shopProductsDestroy(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a product
         * @summary Updates a product
         * @param {number} productId 
         * @param {PatchedUpdateProductDto} [patchedUpdateProductDto] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shopProductsPartialUpdate(productId: number, patchedUpdateProductDto?: PatchedUpdateProductDto, options?: any): AxiosPromise<ProductDetailsDto> {
            return localVarFp.shopProductsPartialUpdate(productId, patchedUpdateProductDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * Changes default quantity of published products
     * @param {number} publishedProductId 
     * @param {PatchedChangeDefaultQuantityDto} [patchedChangeDefaultQuantityDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public changeDefaultQuantity(publishedProductId: number, patchedChangeDefaultQuantityDto?: PatchedChangeDefaultQuantityDto, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).changeDefaultQuantity(publishedProductId, patchedChangeDefaultQuantityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Changes quantity of published products
     * @param {number} publishedProductId 
     * @param {PatchedChangeQuantityDto} [patchedChangeQuantityDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public changeQuantity(publishedProductId: number, patchedChangeQuantityDto?: PatchedChangeQuantityDto, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).changeQuantity(publishedProductId, patchedChangeQuantityDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new product
     * @summary Creates a new product
     * @param {CreateProductDto} createProductDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public createInventoryProduct(createProductDto: CreateProductDto, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).createInventoryProduct(createProductDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a product
     * @summary Fetches details about a product
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public fetchSingleProduct(productId: number, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).fetchSingleProduct(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetches orders which were bought and are waiting for collection
     * @param {number} branchOfficeId 
     * @param {string} [collectionId] 
     * @param {string} [filterFrom] 
     * @param {string} [filterTo] 
     * @param {Array<GetAllOrdersOrderStatusEnum>} [orderStatus] 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public getAllOrders(branchOfficeId: number, collectionId?: string, filterFrom?: string, filterTo?: string, orderStatus?: Array<GetAllOrdersOrderStatusEnum>, page?: number, pageSize?: number, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).getAllOrders(branchOfficeId, collectionId, filterFrom, filterTo, orderStatus, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all products in a company if user is admin. Otherwise only products in branch office are listed
     * @summary Returns products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public getInventoryProducts(options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).getInventoryProducts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Validates original and discounted price. Helpful to clients
     * @param {number} discount 
     * @param {number} ogPrice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopPricingRetrieve(discount: number, ogPrice: number, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopPricingRetrieve(discount, ogPrice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * deletes a product
     * @summary Deletes a product
     * @param {number} productId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopProductsDestroy(productId: number, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopProductsDestroy(productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a product
     * @summary Updates a product
     * @param {number} productId 
     * @param {PatchedUpdateProductDto} [patchedUpdateProductDto] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public shopProductsPartialUpdate(productId: number, patchedUpdateProductDto?: PatchedUpdateProductDto, options?: RawAxiosRequestConfig) {
        return ShopApiFp(this.configuration).shopProductsPartialUpdate(productId, patchedUpdateProductDto, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetAllOrdersOrderStatusEnum = {
    Reserved: 'reserved',
    Collected: 'collected'
} as const;
export type GetAllOrdersOrderStatusEnum = typeof GetAllOrdersOrderStatusEnum[keyof typeof GetAllOrdersOrderStatusEnum];


/**
 * StatisticsApi - axios parameter creator
 * @export
 */
export const StatisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicStatistics: async (branchOfficeId: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchOfficeId' is not null or undefined
            assertParamExists('getBasicStatistics', 'branchOfficeId', branchOfficeId)
            const localVarPath = `/api/statistics/basic/{branchOfficeId}/`
                .replace(`{${"branchOfficeId"}}`, encodeURIComponent(String(branchOfficeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatisticsApi - functional programming interface
 * @export
 */
export const StatisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBasicStatistics(branchOfficeId: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasicStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBasicStatistics(branchOfficeId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['StatisticsApi.getBasicStatistics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * StatisticsApi - factory interface
 * @export
 */
export const StatisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} branchOfficeId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBasicStatistics(branchOfficeId: number, options?: any): AxiosPromise<BasicStatistics> {
            return localVarFp.getBasicStatistics(branchOfficeId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatisticsApi - object-oriented interface
 * @export
 * @class StatisticsApi
 * @extends {BaseAPI}
 */
export class StatisticsApi extends BaseAPI {
    /**
     * 
     * @param {number} branchOfficeId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsApi
     */
    public getBasicStatistics(branchOfficeId: number, options?: RawAxiosRequestConfig) {
        return StatisticsApiFp(this.configuration).getBasicStatistics(branchOfficeId, options).then((request) => request(this.axios, this.basePath));
    }
}



